
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import { audienceVariation } from '@/modules/launch/store/state'
import SettingsCard from '@/components/SettingsCard.vue'
import { cloneDeep } from 'lodash'
import AudienceForm from './AudienceForm.vue'

@Component({
  components: { SettingsCard, AudienceForm }
})
export default class AudienceVariationSettings extends VueStrong {
  @Prop({ type: Array, required: true }) variations!: audienceVariation[]

  variationType = 'audienceVariations'
  variationObject = {
    locales: [],
    interests: [],
    geo_locations: ['IT'],
    selectedTargetInterests: [],
    selectedTargetLocales: []
  }
  expanded = [0]

  getExpanded() {
    this.expanded = this.variations.length > 0 ? [this.variations.length - 1] : [0]
  }
  appendNewVariation() {
    this.variations.push(cloneDeep(this.variationObject))
    this.$emit('update', this.variations)
    this.getExpanded()
  }

  removeVariation(index: number) {
    this.variations.splice(index, 1)
    this.$emit('update', this.variations)
  }
  updateAutocompleteForms(value: any) {
    console.log(' value', value)
  }
}
