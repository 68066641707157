
import { Component, Watch } from 'vue-property-decorator'
import TemplatesUpsert from '@/modules/template/components/TemplatesUpsert.vue'
import TemplatesList from '@/modules/template/components/TemplatesList.vue'
import VueStrong from '@/VueStrong'
import { ITemplate, SnackbarParams } from '@/modules/template/store/state'

@Component({ components: { TemplatesUpsert, TemplatesList } })
export default class Templates extends VueStrong {
  activeTab = 0

  tabs = [
    { text: 'Campaign', type: 'campaign' },
    { text: 'AdSet', type: 'adset' },
    { text: 'AdCreative', type: 'adcreative' }
  ]

  get loading() {
    return this.$store.state.template.loading
  }

  get type() {
    return this.$store.state.template.type
  }

  get params() {
    return this.$store.state.template.params[this.type]
  }

  get snackbar() {
    return this.$store.state.template.snackbar
  }

  set snackbar(params: SnackbarParams | null) {
    this.$store.dispatch('template/setSnackbar', params || null)
  }

  get selectedTemplate() {
    return this.$store.state.template.selectedTemplate
  }

  set selectedTemplate(template: ITemplate | null) {
    this.$store.dispatch('template/setSelectedTemplate', template || null)
  }

  newTemplate() {
    this.$store.dispatch('template/newTemplate')
  }

  @Watch('activeTab')
  async onActiveTabChange() {
    let type = 'campaign'
    if (this.activeTab === 1) type = 'adset'
    if (this.activeTab === 2) type = 'adcreative'

    await this.$store.dispatch('template/setParams', { ...this.params, page: 1 })
    await this.$store.dispatch('template/setType', type)
    await this.getTemplates()
  }

  created() {
    return this.getTemplates()
  }

  async getTemplates() {
    await this.$store.dispatch('template/getTemplates', this.type)
  }
}
