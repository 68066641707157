
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import SettingsCard from '@/components/SettingsCard.vue'
import NumberInput from '@/components/NumberInput.vue'
import { bidStrategies } from '@/helpers/data'
@Component({
  components: { SettingsCard, NumberInput }
})
export default class BudgetSettings extends VueStrong {
  @Prop({ type: Object, required: true }) adSet!: yakkyo.IFacebookAdTemplateAdSet

  get selectedAdAccount() {
    return this.$store.state.account ? this.$store.state.account.adAccount : null
  }

  get currency() {
    if (!this.selectedAdAccount) return 'USD'
    return this.selectedAdAccount.currency || 'USD'
  }

  get spendCapLabel() {
    return this.adSet.budgetWindow === 'LIFETIME'
      ? `Lifetime Spend CAP (${this.currency})`
      : `Daily Spend CAP (${this.currency})`
  }
  get bidStrategies() {
    return bidStrategies
  }
  updateValue(key: string, value: any) {
    this.adSet[key] = value
    this.$emit('update', this.adSet)
  }
}
