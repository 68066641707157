
import { Component, PropSync } from 'vue-property-decorator'
import CampaignBudget from '@/modules/campaign/components/CampaignBudget.vue'
import CampaignObjective from '@/modules/campaign/components/CampaignObjective.vue'
import VueStrong from '@/VueStrong'

@Component({ components: { CampaignBudget, CampaignObjective } })
export default class TemplateCampaign extends VueStrong {
  @PropSync('value', { type: Object }) editableValue: Partial<yakkyo.IFacebookAdTemplateCampaign>
}
