
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import SettingsCard from '@/components/SettingsCard.vue'
import { billingEvents, objectives } from '@/helpers/data'

import BudgetSettings from './BudgetSettings.vue'
import OptimizationSettings from './OptimizationSettings.vue'
import AudienceVariationSettings from './AudienceVariationSettings.vue'
import AgeVariationSettings from './AgeVariationSettings.vue'
import PlacementVariationSettings from './PlacementVariationSettings.vue'

@Component({
  components: {
    SettingsCard,
    BudgetSettings,
    OptimizationSettings,
    AudienceVariationSettings,
    AgeVariationSettings,
    PlacementVariationSettings
  }
})
export default class EditAdSetTemplate extends VueStrong {
  @Prop({ type: Object, required: true }) adSet!: yakkyo.IFacebookAdTemplateAdSet | any

  campaignSettings = {
    cbo: false,
    budgetWindow: 'LIFETIME',
    objective: 'OUTCOME_ENGAGEMENT'
  }

  getOptimizationGoals(objective: string) {
    const targetObjective = objectives.find(o => o.value === objective)
    return targetObjective ? targetObjective.optimizationGoals : []
  }

  getConversionEvents(objective: string) {
    const targetObjective = objectives.find(o => o.value === objective)
    return targetObjective ? targetObjective.conversionEvents : []
  }

  getBillingEvents(optimizationGoal: string) {
    return billingEvents[optimizationGoal] || []
  }
  updateAdSet(adSet: yakkyo.IFacebookAdTemplateAdSet) {
    this.adSet = adSet
  }

  updateAudienceVariations(value: any) {
    this.adSet.variations.audienceVariations = value
  }
  updateAgeVariations(value: any) {
    this.adSet.variations.ageVariations = value
  }
  updatePlacementVariations(value: any) {
    this.adSet.variations.placementVariations = value
  }
}
