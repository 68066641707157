
import { Component, Prop } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import VueStrong from '@/VueStrong'
import SettingsCard from '@/components/SettingsCard.vue'
import UrlForm from './UrlForm.vue'

@Component({
  components: {
    SettingsCard,
    UrlForm
  }
})
export default class UrlVariationSettings extends VueStrong {
  @Prop({ type: Array, required: true }) variations!: any

  expanded: number[] = [0]
  generalVariationObject = {
    name: '',
    status: 'ACTIVE',
    link: '',
    title: '',
    description: '',
    body: '',
    callToAction: ''
  }
  getExpanded() {
    this.expanded = this.variations.length > 0 ? [this.variations.length - 1] : [0]
  }
  appendNewGeneralVariation() {
    this.variations.push(cloneDeep(this.generalVariationObject))
    this.getExpanded()
    this.updateUrlSettings()
  }
  removeGeneralVariation(index: number) {
    this.variations.splice(index, 1)
    this.updateUrlSettings()
  }

  updateUrlTagsVariations(variation: any, index: any) {
    this.variations[index] = variation
    this.updateUrlSettings()
  }
  updateUrlSettings() {
    this.$emit('update', this.variations)
  }
}
