
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import SettingsCard from '@/components/SettingsCard.vue'
import NumberInput from '@/components/NumberInput.vue'
import { billingEvents, objectives, attributions } from '@/helpers/data'

@Component({
  components: { SettingsCard, NumberInput }
})
export default class OptimizationSettings extends VueStrong {
  @Prop({ type: Object, required: true }) adSet!: yakkyo.IFacebookAdTemplateAdSet | any

  required = [v => !!v || '*Required']
  arrayRequried = [v => (!!v && Array.isArray(v) && !!v.length) || '*Required']

  campaign = {
    cbo: false,
    objective: 'OUTCOME_ENGAGEMENT'
  }

  mounted() {
    if (this.adSet.attribution_spec.length > 0) delete this.adSet.attribution_spec[0]._id
  }

  get attribution() {
    return this.adSet.attribution_spec.length > 0 ? this.adSet.attribution_spec : null
  }

  get attributions() {
    return attributions
  }

  get targetObjective() {
    return objectives.find(o => o.value === this.campaign.objective)
  }

  get optiomizationGoals() {
    return this.targetObjective ? this.targetObjective.optimizationGoals : []
  }

  get billingEvents() {
    return billingEvents[this.adSet.optimization_goal] || []
  }

  get conversionEvents() {
    return this.targetObjective ? this.targetObjective.conversionEvents : []
  }

  get showBid() {
    return this.adSet.bid_strategy !== 'LOWEST_COST_WITHOUT_CAP'
  }
}
