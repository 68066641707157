
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import TemplateCardAdSet from '@/modules/adset/components/TemplateCardAdSet.vue'
import TemplateCardAdCreative from '@/modules/adcreative/components/TemplateCardAdCreative.vue'
import TemplateCardCampaign from '@/modules/campaign/components/TemplateCardCampaign.vue'
import { SnackbarParams } from '@/modules/template/store/state'

@Component({
  components: {
    TemplateCardAdSet,
    TemplateCardAdCreative,
    TemplateCardCampaign
  }
})
export default class TemplateCard extends VueStrong {
  @Prop({ required: true }) template: any
  @Prop({ default: false }) disableActions: boolean
  @Prop({ default: 'campaign' }) type: 'campaign' | 'adset' | 'adcreative'

  get component() {
    if (this.type === 'adset') return 'TemplateCardAdSet'
    else if (this.type === 'adcreative') return 'TemplateCardAdCreative'
    return 'TemplateCardCampaign'
  }

  deleteDialog = false

  edit() {
    this.$store.dispatch('template/setSelectedTemplate', this.template)
  }

  async remove() {
    // TODO: move logic to store
    try {
      const params = { id: this.template._id, type: this.type }
      await this.$store.dispatch('template/deleteTemplate', params)
      await this.$store.dispatch('template/setSnackbar', {
        type: 'success',
        text: 'Done!'
      } as SnackbarParams)
    } catch (e) {
      await this.$store.dispatch('template/setSnackbar', {
        type: 'error',
        text: 'Something went wrong!'
      } as SnackbarParams)
    }
  }
}
