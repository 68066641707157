
import { Component, Prop } from 'vue-property-decorator'
import TemplateCard from '@/modules/template/components/TemplateCard.vue'
import VueStrong from '@/VueStrong'
import { TemplateParams } from '@/modules/template/store/state'

@Component({ components: { TemplateCard } })
export default class TemplatesList extends VueStrong {
  @Prop({ default: false }) type: 'adset' | 'adcreative' | 'campaign'

  get templates() {
    return this.$store.state.template.templates[this.type] || []
  }

  get params() {
    return this.$store.state.template.params[this.type]
  }

  get pageCount() {
    return Math.ceil(this.params.serverItemsLength / this.params.itemsPerPage) || 0
  }

  async updatePage(page: number): Promise<void> {
    const params: TemplateParams = { ...this.params, page }
    this.$store.dispatch('template/setParams', { params })
  }

  // onTemplateSelect(template: yakkyo.IFacebookAdTemplate) {
  //   console.log('onTemplateSelect', template)
  //   if (this.selectEnabled) this.$emit('select', template)
  // }
}
