
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import { ageVariation } from '@/modules/launch/store/state'
import SettingsCard from '@/components/SettingsCard.vue'
import { cloneDeep } from 'lodash'
import NumberInput from '@/components/NumberInput.vue'
@Component({
  components: { SettingsCard, NumberInput }
})
export default class AgeVariationSettings extends VueStrong {
  @Prop({ type: Array, required: true }) variations!: ageVariation[]

  expanded = [0]
  variationObject = {
    genders: {
      male: true,
      female: true
    },
    age_max: 65,
    age_min: 13
  }

  get selectedAdAccount() {
    return this.$store.state.account?.adAccount || null
  }

  get isMaxAdSetVariations() {
    return this.$store.getters['launch/isMaxAdSetVariations']
  }

  getExpanded() {
    this.expanded = this.variations.length > 0 ? [this.variations.length - 1] : [0]
  }

  formatNumber(value: string) {
    const formatter = Intl.NumberFormat('en', { notation: 'compact' })
    return formatter.format(Number(value))
  }

  appendNewVariation() {
    this.variations.push(cloneDeep(this.variationObject))
    this.getExpanded()
    this.updateGeneralSettings()
  }

  removeVariation(index: number) {
    this.variations.splice(index, 1)
    this.updateGeneralSettings()
  }
  updateMinAge(value, i) {
    this.variations[i].age_min = Number(value)
    this.updateGeneralSettings()
  }
  updateMaxAge(value, i) {
    this.variations[i].age_max = Number(value)
    this.updateGeneralSettings()
  }
  updateGeneralSettings() {
    this.$emit('update', this.variations)
  }
}
