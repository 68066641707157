
import { Component, Prop } from 'vue-property-decorator'
import { debounce } from 'lodash'
import VueStrong from '@/VueStrong'
import { SnackbarParams } from '@/modules/template/store/state'

import TemplateCampaign from '@/modules/campaign/components/TemplateCampaign.vue'
import EditAdSetTemplate from './AdSet/EditAdSetTemplate.vue'
import EditAdCreativeTemplate from './AdCreative/EditAdCreativeTemplate.vue'

@Component({ components: { TemplateCampaign, EditAdSetTemplate, EditAdCreativeTemplate } })
export default class TemplatesUpsert extends VueStrong {
  @Prop({ default: 'campaign' }) type: 'campaign' | 'adset' | 'adcreative'

  debounceUpsertTemplate = debounce(this.upsertTemplate, 500)
  editableTemplate = null
  snackbarError = false
  snackbarSuccess = false

  created() {
    this.editableTemplate = this.template
  }

  get template() {
    return this.$store.state.template.selectedTemplate
  }

  get loading() {
    return this.$store.state.template.loading
  }
  get snackbar() {
    return this.$store.state.template.snackbar
  }

  set snackbar(params: SnackbarParams | null) {
    this.$store.dispatch('template/setSnackbar', params || null)
  }

  get btnDisabled() {
    if (!this.editableTemplate.name) return true
    else return false
  }

  async upsertTemplate() {
    const isValid = (this.$refs.form as any).validate()
    if (!isValid) return

    try {
      const data = { template: this.editableTemplate, type: this.type }

      if (!this.template._id) await this.$store.dispatch('template/createTemplate', data)
      else await this.$store.dispatch('template/updateTemplate', { ...data, id: this.template._id })

      await this.$store.dispatch('template/setSnackbar', {
        type: 'success',
        text: 'Done!'
      } as SnackbarParams)
    } catch (e) {
      console.log(e)
      await this.$store.dispatch('template/setSnackbar', {
        type: 'error',
        text: 'Something went wrong!'
      } as SnackbarParams)
    }
  }
}
