
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import SettingsCard from '@/components/SettingsCard.vue'
import GeneralVariationSettings from './GeneralVariationSettings.vue'
import UrlVariationSettings from './UrlVariationSettings.vue'

@Component({
  components: {
    SettingsCard,
    GeneralVariationSettings,
    UrlVariationSettings
  }
})
export default class EditAdCreativeTemplate extends VueStrong {
  @Prop({ type: Object, required: true }) adCreative!: yakkyo.IFacebookAdTemplateAdCreative | any

  updateAdCreative(adCreative: yakkyo.IFacebookAdTemplateAdCreative) {
    this.adCreative = adCreative
  }

  updateGeneralVariations(value: any) {
    this.adCreative.generalVariations = value
  }
  updateUrlVariations(value: any) {
    this.adCreative.urlTagsVariations = value
  }
}
