
import { Component, Prop } from 'vue-property-decorator'
import TemplateInput from '@/modules/template/components/TemplateInput.vue'
import VueStrong from '@/VueStrong'

@Component({ components: { TemplateInput } })
export default class GeneralForm extends VueStrong {
  @Prop({ type: Object, required: true }) variation!: any
  @Prop({ type: Number, required: true }) index!: any

  required = (val: string): boolean | string => !!val || 'Required'
}
